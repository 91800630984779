import type { PropsWithChildren } from 'react'

import { cn } from '@mntn-dev/ui-utilities'
import type { TextOptions } from '../../classes'
import type { TestIds } from '../../utils'
import { Text, type TextProps } from '../text'

export type BladeTitleProps = Readonly<
  PropsWithChildren<
    Pick<TextProps, keyof TextOptions> & {
      truncate?: boolean
    } & TestIds
  >
>

export const BladeTitle = ({
  children,
  truncate,
  ...props
}: BladeTitleProps) => {
  return (
    <Text
      className={cn(truncate ? 'truncate' : 'line-clamp-1 flex grow')}
      textColor={props.textColor ?? 'primary'}
      fontSize={props.fontSize ?? 'lg'}
      fontWeight={props.fontWeight ?? 'bold'}
      {...props}
      dataTestId={props.dataTestId}
      dataTrackingId={props.dataTrackingId}
    >
      {children}
    </Text>
  )
}
