import {
  themeBackgroundMap,
  themeHoverBackgroundMap,
  themeRingColorMap,
} from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import {
  type KeyboardEvent,
  type MouseEvent,
  type PropsWithChildren,
  forwardRef,
} from 'react'
import { Icon } from '../icon/icon.tsx'
import { Text } from '../text/text.tsx'

type ChipVariant = 'pill' | 'rounded-square' | 'square'

const chipVariantMap: Record<ChipVariant, string> = {
  pill: 'rounded-full',
  'rounded-square': 'rounded',
  square: 'rounded-none',
}

type ChipProps = Readonly<
  PropsWithChildren<{
    className?: string
    dismissable?: boolean
    disabled?: boolean
    id?: string
    onDismissClick?: (e: MouseEvent<HTMLButtonElement>) => void
    onSelected?: (selected: boolean) => void
    selectable?: boolean
    selected?: boolean
    variant?: ChipVariant
  }>
>

const Chip = forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      children,
      className,
      disabled,
      dismissable,
      id,
      onDismissClick,
      onSelected,
      selectable,
      selected,
      variant = 'pill',
    }: ChipProps,
    ref
  ) => {
    const handleSelected = () => {
      if (onSelected) {
        onSelected(!selected)
      }
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === ' ' && onSelected) {
        onSelected(!selected)
      }
    }

    return (
      <div
        ref={ref}
        className={cn(
          'inline-flex w-fit gap-2 h-8 justify-center items-center px-4 flex-none',
          chipVariantMap[variant],
          {
            'pr-3': dismissable,
            [`ring-2 ring-inset ${themeRingColorMap.medium} ${themeBackgroundMap['lighten-secondary']}`]:
              selected,
            [`${themeBackgroundMap.lighten}`]: !selected,
            [`${themeHoverBackgroundMap.lighten} cursor-pointer`]:
              selectable && !selected,
            'cursor-pointer': selectable,
            className,
          }
        )}
        onClick={selectable ? handleSelected : undefined}
        onKeyDown={selectable ? handleKeyDown : undefined}
        data-id={id}
      >
        <Text
          fontSize="sm"
          className="leading-normal whitespace-nowrap text-ellipsis overflow-hidden"
          textColor={disabled ? 'disabled' : 'primary'}
        >
          {children}
        </Text>
        {dismissable && (
          <button
            type="button"
            onClick={onDismissClick}
            className={cn(
              'p-0.5 -m-0.5 rounded-full',
              themeHoverBackgroundMap.lighten
            )}
          >
            <Icon name="XMarkIcon" size="sm" color="tertiary" />
          </button>
        )}
      </div>
    )
  }
)

export { Chip }
