'use client'

import { cn } from '@mntn-dev/ui-utilities'
import { type MouseEvent, type PropsWithChildren, useRef } from 'react'

import {
  type ThemeFlexParent,
  type ThemeGapValue,
  getFlexParentClassName,
  themeBackgroundBlurMap,
  themeBackgroundMap,
  themeBorderColorMap,
  themeHoverBackgroundMap,
} from '@mntn-dev/ui-theme'

import { useScrollIntoView } from '../../hooks/index.ts'
import { type TestIds, getTestProps } from '../../utils'
import { BannerInfo } from './banner/banner-info.tsx'
import { BladeChat } from './blade-chat.tsx'
import { BladeCheckoutItem } from './blade-checkout-item.tsx'
import { BladeColumn } from './blade-column.tsx'
import { BladeEnhancementInfo } from './blade-enhancement-info.tsx'
import { BladeImage } from './blade-image.tsx'
import { BladeLeft } from './blade-left.tsx'
import { BladeRight } from './blade-right.tsx'
import { BladeTag } from './blade-tag.tsx'
import { BladeTitle } from './blade-title.tsx'
import { BladeMediaIcon } from './media/blade-media-icon.tsx'
import { BladeMediaInfo } from './media/blade-media-info.tsx'
import { BladeMediaMenu } from './media/blade-media-menu.tsx'

type BladeType =
  | 'banner'
  | 'checkout'
  | 'enhancement'
  | 'media'
  | 'mini'
  | 'service'

type BladeProps = Readonly<
  PropsWithChildren<
    TestIds &
      ThemeFlexParent & {
        type: BladeType
        className?: string
        isSelectable?: boolean
        isSelected?: boolean
        hasHoverState?: boolean
        onClick?: (e: MouseEvent<HTMLDivElement>) => void
        autoFocus?: boolean
        disabled?: boolean
      }
  >
>

const stylesByType: Record<BladeType, string> = {
  banner: `h-[280px] rounded-lg ${themeBackgroundMap['container-secondary']} before:${themeBackgroundBlurMap['blur-md-shadow']}`,
  checkout: 'min-h-14 px-8 py-4 self-stretch',
  enhancement: `rounded-lg ${themeBackgroundMap['container-secondary']} before:${themeBackgroundBlurMap['blur-md']}`,
  media: 'min-h-[4.5rem] pl-8 py-4 justify-between',
  mini: `h-12 px-4 mb-2 rounded-md ${themeHoverBackgroundMap.tertiary} border ${themeBorderColorMap.muted} ${themeBackgroundMap['container-secondary']}`,
  service: 'min-h-14',
}

const gapByType: Record<BladeType, ThemeGapValue | undefined> = {
  banner: undefined,
  checkout: '4',
  enhancement: '6',
  media: '4',
  mini: '2',
  service: '4',
}

const getSelectableStyles = (type: BladeType, isSelected: boolean) => {
  switch (type) {
    case 'enhancement':
      return `${isSelected ? 'shadow-glow-blue-lg' : 'hover:shadow-glow-blue-lg'} border ${isSelected ? themeBorderColorMap.info : themeBorderColorMap.transparent}`
    case 'media': {
      return isSelected
        ? `border-l-4 solid ${themeBorderColorMap.info}`
        : 'border-l-4 border-transparent'
    }
    default:
      return ''
  }
}

const Blade = ({
  type,
  className,
  dataTestId,
  dataTrackingId,
  isSelectable,
  isSelected,
  hasHoverState,
  onClick,
  autoFocus,
  disabled,
  children,
  gap = gapByType[type],
  ...props
}: BladeProps) => {
  const innerRef = useRef<HTMLDivElement>(null)
  const { mergedRef } = useScrollIntoView({ scroll: autoFocus, innerRef })
  const styles = stylesByType[type]

  return (
    <div
      ref={mergedRef}
      className={cn(
        'flex w-full max-w-full items-center',
        styles,
        isSelectable ? getSelectableStyles(type, !!isSelected) : null,
        hasHoverState ? themeHoverBackgroundMap.tertiary : null,
        onClick ? 'cursor-pointer' : null,
        getFlexParentClassName({ ...props, gap }),
        disabled && 'opacity-50 pointer-events-none',
        className
      )}
      {...getTestProps({ dataTestId, dataTrackingId })}
      onClick={(e) => {
        e.stopPropagation()
        onClick?.(e)
      }}
    >
      {children}
    </div>
  )
}

const BladeNamespace = Object.assign(Blade, {
  Left: BladeLeft,
  Right: BladeRight,
  Title: BladeTitle,
  Image: BladeImage,
  Chat: BladeChat,
  Tag: BladeTag,
  CheckoutItem: BladeCheckoutItem,
  EnhancementInfo: BladeEnhancementInfo,
  MediaIcon: BladeMediaIcon,
  MediaInfo: BladeMediaInfo,
  MediaMenu: BladeMediaMenu,
  BannerInfo: BannerInfo,
  Column: BladeColumn,
})

export { BladeNamespace as Blade, type BladeProps }
