'use client'

import Autosizer from 'react-virtualized-auto-sizer'
import {
  VariableSizeList as List,
  type ListChildComponentProps,
} from 'react-window'

import type { ComponentType } from 'react'
import {
  type UseVirtualizedListProps,
  type UseVirtualizedListReturn,
  VirtualizedListProvider,
  useVirtualizedList,
} from './use-virtualized-list.ts'

type VirtualizedListProps<T> = UseVirtualizedListProps & {
  children: ComponentType<ListChildComponentProps<T[]>>
  data: T[]
}

function VirtualizedList<T>({
  children,
  data,
  ...contextProps
}: VirtualizedListProps<T>) {
  const context = useVirtualizedList<T>(contextProps)

  const { getRowHeight, listRef } = context

  const getItemSize = (index: number) => {
    return getRowHeight(index)
  }

  return (
    <div className="w-full h-full">
      {/* TODO: QF-2738 fix this type */}
      <VirtualizedListProvider value={context as UseVirtualizedListReturn}>
        <Autosizer className="h-full w-full">
          {({ height, width }) => (
            <List
              height={height}
              itemCount={data.length}
              itemData={data}
              itemSize={getItemSize}
              width={width}
              ref={listRef}
            >
              {children}
            </List>
          )}
        </Autosizer>
      </VirtualizedListProvider>
    </div>
  )
}

export { VirtualizedList, type VirtualizedListProps }
