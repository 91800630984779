'use client'

import { useCallback, useRef } from 'react'
import type { VariableSizeList as List } from 'react-window'
import { createContext } from '../../utils/context.ts'

type UseVirtualizedListProps = {
  fallbackHeight?: number
  gap?: number
}

function useVirtualizedList<T>({
  fallbackHeight = 300,
  gap = 8,
}: UseVirtualizedListProps) {
  const listRef = useRef<List<T[]> | null>(null)
  const rowHeights = useRef<Record<number, number>>([])

  const setRowHeight = useCallback((height: number, index: number) => {
    listRef.current?.resetAfterIndex(0)
    rowHeights.current[index] = height
  }, [])

  const getRowHeight = (index: number) => {
    return (rowHeights.current[index] ?? fallbackHeight) + gap
  }

  return {
    getRowHeight,
    listRef,
    setRowHeight,
  }
}

export type UseVirtualizedListReturn<T = unknown> = ReturnType<
  typeof useVirtualizedList<T>
>

const [VirtualizedListProvider, useVirtualizedListContext] =
  createContext<UseVirtualizedListReturn>({ name: 'VirtualizedListContext' })

export {
  useVirtualizedList,
  VirtualizedListProvider,
  useVirtualizedListContext,
  type UseVirtualizedListProps,
}
