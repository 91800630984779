import type { SvgIconProps } from './types/props.ts'

export const BigXIcon = (props: SvgIconProps) => {
  return (
    <svg
      {...props}
      width="128"
      height="129"
      viewBox="0 0 128 129"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Big X Icon"
      role="img"
    >
      <g id="solar:close-circle-broken">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M107.884 64.4945C107.896 56.7936 105.871 49.2266 102.014 42.5609C101.03 40.8599 101.611 38.6833 103.312 37.6992C105.013 36.7151 107.19 37.2963 108.174 38.9973C112.658 46.7485 115.013 55.5478 115 64.5027C114.998 92.6687 92.1663 115.5 64 115.5C35.8327 115.5 13 92.6672 13 64.5C13 36.3328 35.8327 13.5 64 13.5C73.2808 13.5 82.0016 15.9835 89.5036 20.3265C91.2042 21.311 91.7848 23.4879 90.8002 25.1885C89.8157 26.8892 87.6389 27.4698 85.9382 26.4852C79.4923 22.7536 71.9975 20.6163 64 20.6163C39.763 20.6163 20.1163 40.263 20.1163 64.5C20.1163 88.737 39.763 108.384 64 108.384C88.237 108.384 107.884 88.737 107.884 64.5L107.884 64.4945ZM115 64.5027L115 64.5055L111.442 64.5L115 64.5L115 64.5027Z"
          fill="url(#paint0_linear_47673_374029)"
          fillOpacity="0.3"
        />
        <g id="Vector (Stroke)_2" filter="url(#filter0_d_47673_374029)">
          <path
            d="M47.2166 47.7166C48.8388 46.0945 51.4689 46.0945 53.0911 47.7166L64 58.6256L74.9089 47.7166C76.5311 46.0945 79.1612 46.0945 80.7834 47.7166C82.4055 49.3388 82.4055 51.9689 80.7834 53.5911L69.8744 64.5L80.7834 75.4089C82.4055 77.0311 82.4055 79.6612 80.7834 81.2834C79.1612 82.9055 76.5311 82.9055 74.9089 81.2834L64 70.3744L53.0911 81.2834C51.4689 82.9055 48.8388 82.9055 47.2166 81.2834C45.5945 79.6612 45.5945 77.0311 47.2166 75.4089L58.1256 64.5L47.2166 53.5911C45.5945 51.9689 45.5945 49.3388 47.2166 47.7166Z"
            fill="url(#paint1_linear_47673_374029)"
            fillOpacity="0.7"
            shapeRendering="crispEdges"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_47673_374029"
          x="38"
          y="38.5"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_47673_374029"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_47673_374029"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_47673_374029"
          x1="115"
          y1="64.5"
          x2="13"
          y2="64.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3333" />
          <stop offset="1" stopColor="#FF3333" stopOpacity="0.7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_47673_374029"
          x1="64"
          y1="46.5"
          x2="64"
          y2="82.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3333" />
          <stop offset="1" stopColor="#FF3333" stopOpacity="0.7" />
        </linearGradient>
      </defs>
    </svg>
  )
}
