import { cn } from '@mntn-dev/ui-utilities'

import {
  themeBackgroundMap,
  themeBorderColorMap,
  themeTextColorMap,
} from '@mntn-dev/ui-theme'

import { type TestIds, getTestProps } from '../../utils'
import { type TabType, useTabsContext } from './use-tabs.tsx'

type TabProps = Readonly<
  {
    id: string
    name: string
    disabled?: boolean
  } & TestIds
>

const TabClassMapByType: Record<
  TabType,
  {
    sharedClasses: string
    defaultClasses: string
    activeClasses: string
  }
> = {
  simple: {
    sharedClasses: 'font-normal h-14',
    defaultClasses: `${themeTextColorMap.primary} transition-all duration-300 ease-in-out border-b border-transparent hover:border-white/90 disabled:cursor-not-allowed hover:disabled:border-none disabled:${themeTextColorMap.disabled}`,
    activeClasses: 'text-primary-blue border-b-2 border-primary-blue',
  },
  bubble: {
    sharedClasses:
      'flex py-2 px-10 justify-center items-center font-medium gap-2 transition-none',
    defaultClasses: `${themeTextColorMap.tertiary} hover:transition-[color] hover:duration-300 hover:text-primary-blue disabled:${themeTextColorMap.disabled} disabled:cursor-not-allowed`,
    activeClasses: `${themeTextColorMap['primary-inverse']} rounded shadow-glow-blue-md border ${themeBorderColorMap.bright} ${themeBackgroundMap.cta}`,
  },
}

const Tab = ({ disabled, name, id, dataTestId, dataTrackingId }: TabProps) => {
  const { current, onClick, type } = useTabsContext()
  const { sharedClasses, defaultClasses, activeClasses } =
    TabClassMapByType[type]
  return (
    <li key={name}>
      <button
        type="button"
        onClick={() => onClick(id)}
        disabled={disabled}
        className={cn(
          current === id ? activeClasses : defaultClasses,
          sharedClasses
        )}
        {...getTestProps({ dataTestId, dataTrackingId })}
      >
        {name}
      </button>
    </li>
  )
}

export { Tab, type TabProps }
