'use client'

import {
  type CSSProperties,
  type MutableRefObject,
  type ReactNode,
  useEffect,
  useRef,
} from 'react'
import { useVirtualizedListContext } from './use-virtualized-list.ts'

type VirtualizedListRowProps<T> = {
  children: (ref: MutableRefObject<HTMLElement | null>, item: T) => ReactNode
  style: CSSProperties
  index: number
  data: T[]
}

function VirtualizedListRow<T>({
  children,
  style,
  index,
  data,
}: VirtualizedListRowProps<T>) {
  const rowRef = useRef<HTMLElement | null>(null)
  const { setRowHeight } = useVirtualizedListContext()

  // biome-ignore lint/correctness/useExhaustiveDependencies: we want to limit how often this effect runs
  useEffect(() => {
    if (rowRef.current) {
      setRowHeight(rowRef.current.offsetHeight, index)
    }
  }, [rowRef])

  const item = data[index]

  if (!item) {
    return null
  }

  return <div style={style}>{children(rowRef, item)}</div>
}

export { VirtualizedListRow, type VirtualizedListRowProps }
