import type { TupleToUnion } from 'type-fest'

export const NavIconNames = [
  'nav-bar-chart',
  'nav-bell',
  'nav-color-wheel',
  'nav-folder',
  'nav-packages',
  'nav-projects',
  'nav-users',
] as const

export type NavIconName = TupleToUnion<typeof NavIconNames>

export const OtherSvgIconNames = [
  'BigCheckIcon',
  'BigXIcon',
  'CelebrationIcon',
  'CircleIcon',
  'DotIcon',
  'EditIcon',
  'ErrorIcon',
  'ExclamationIcon',
  'InfoIcon',
  'LoadingDots',
  'ProductionCameraIcon',
  'SuccessIcon',
  'VideoIcon',
  'WarningIcon',
] as const

export const SvgIconNames = [...NavIconNames, ...OtherSvgIconNames] as const

export type SvgIconName = TupleToUnion<typeof SvgIconNames>

export const isSvgIconName = (name: unknown): name is SvgIconName =>
  SvgIconNames.includes(name as SvgIconName)
