import type { SvgIconProps } from './types/props.ts'

export const BigCheckIcon = (props: SvgIconProps) => {
  return (
    <svg
      {...props}
      width="128"
      height="129"
      viewBox="0 0 128 129"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Big Check Icon"
      role="img"
    >
      <g id="prime:check-circle">
        <g id="Vector" filter="url(#filter0_d_47604_213995)">
          <path
            d="M55.9986 81.8336C54.9696 81.7549 54.0123 81.2762 53.3319 80.5002L37.3319 64.5002C36.9754 63.7538 36.8591 62.9152 36.999 62.1C37.1389 61.2847 37.5281 60.5328 38.113 59.9479C38.6979 59.363 39.4497 58.9739 40.265 58.834C41.0803 58.6941 41.9188 58.8104 42.6653 59.1669L55.8386 72.3402L101.332 27.1669C102.078 26.8104 102.917 26.6941 103.732 26.834C104.547 26.9739 105.299 27.363 105.884 27.9479C106.469 28.5328 106.858 29.2847 106.998 30.1C107.138 30.9152 107.022 31.7538 106.665 32.5002L58.6653 80.5002C57.9849 81.2762 57.0276 81.7549 55.9986 81.8336Z"
            fill="url(#paint0_linear_47604_213995)"
            shapeRendering="crispEdges"
          />
        </g>
        <g id="Vector_2" opacity="0.3" filter="url(#filter1_d_47604_213995)">
          <path
            d="M64.0006 112.5C55.4217 112.487 47.0033 110.174 39.6215 105.803C32.2397 101.432 26.1641 95.1626 22.0272 87.6471C18.8826 82.047 16.9391 75.8532 16.3206 69.4605C15.3445 60.0849 17.1505 50.6294 21.5134 42.2736C25.8762 33.9178 32.6028 27.0315 40.8539 22.4738C46.454 19.3292 52.6478 17.3857 59.0406 16.7671C65.4094 16.0821 71.8509 16.699 77.9739 18.5805C78.5203 18.6896 79.038 18.9118 79.4935 19.2327C79.9491 19.5536 80.3326 19.9661 80.6195 20.4438C80.9063 20.9216 81.0902 21.454 81.1593 22.0069C81.2284 22.5599 81.1812 23.1211 81.0208 23.6548C80.8603 24.1884 80.5901 24.6826 80.2275 25.1058C79.8649 25.5289 79.4179 25.8716 78.9151 26.1119C78.4123 26.3522 77.8649 26.4848 77.3079 26.5012C76.7509 26.5176 76.1966 26.4174 75.6806 26.2071C70.4998 24.6494 65.0601 24.1417 59.6806 24.7138C54.3632 25.2583 49.211 26.8729 44.5339 29.4605C40.0279 31.9463 36.044 35.2784 32.8006 39.2738C29.4612 43.344 26.9675 48.0396 25.4656 53.0856C23.9637 58.1316 23.4838 63.4266 24.0539 68.6605C24.5984 73.9778 26.213 79.13 28.8006 83.8071C31.2865 88.3131 34.6185 92.297 38.6139 95.5405C42.6841 98.8799 47.3797 101.374 52.4257 102.875C57.4718 104.377 62.7667 104.857 68.0006 104.287C73.3179 103.743 78.4702 102.128 83.1472 99.5405C87.6532 97.0546 91.6371 93.7226 94.8806 89.7271C98.22 85.6569 100.714 80.9613 102.216 75.9153C103.717 70.8693 104.197 65.5743 103.627 60.3405C103.521 59.2301 103.86 58.1231 104.571 57.2629C105.281 56.4027 106.304 55.8599 107.414 55.7538C108.524 55.6477 109.631 55.9871 110.491 56.6972C111.352 57.4073 111.894 58.4301 112.001 59.5405C112.972 68.9216 111.158 78.381 106.785 86.7374C102.412 95.0938 95.6749 101.977 87.4139 106.527C81.7543 109.8 75.4648 111.836 68.9606 112.5H64.0006Z"
            fill="#1FC38F"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_47604_213995"
          x="28.9414"
          y="18.7764"
          width="86.1133"
          height="71.0576"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.121569 0 0 0 0 0.764706 0 0 0 0 0.560784 0 0 0 0.7 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_47604_213995"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_47604_213995"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_47604_213995"
          x="8.0625"
          y="8.49609"
          width="112.191"
          height="112.004"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.121569 0 0 0 0 0.764706 0 0 0 0 0.560784 0 0 0 0.7 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_47604_213995"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_47604_213995"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_47604_213995"
          x1="71.9986"
          y1="26.7764"
          x2="71.9986"
          y2="81.8336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1FC38F" />
          <stop offset="1" stopColor="#1FC38F" stopOpacity="0.7" />
        </linearGradient>
      </defs>
    </svg>
  )
}
